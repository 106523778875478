import Image from 'next/image'
import type { FC } from 'react'
import styled from 'styled-components'

import { useGetCarrierQuery } from '@redux/features/trainData/trainData.api'

type Props = {
  carrierId: number
}

const CarrierItem: FC<Props> = ({ carrierId }) => {
  const { data } = useGetCarrierQuery(carrierId)

  if (!data) return null

  return (
    <Container>
      <Image alt={data.name} height="40" loading="lazy" src={data.logo?.uri?.url || ''} width="120" />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  transition: all ${p => p.theme.transitions.default};
  filter: ${p => p.theme.colors.iconCarrierFilter};
  opacity: ${p => p.theme.opacity.normalIcon};
  cursor: pointer;

  &:hover {
    filter: grayscale(0%);
    opacity: 1;
  }

  img {
    object-fit: contain;
    width: auto;
    max-width: 150px;

    ${p => p.theme.mediaQueries.mobile} {
      height: 32px;
      max-width: 100px;
    }
  }
`

export default CarrierItem

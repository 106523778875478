import { type FC, memo } from 'react'

import { BlocksKeys } from '@redux/features/homePage/constants/blockHomePage.constants'

import type { ExtendBlocksType } from '@Types/blocks/blocks'
import type { CarriersType } from '@Types/blocks/carriers'
import CarrierItem from '@components/mainPageBlocks/Carriers/components/CarrierItem'

import { Container, List } from './styles'

type Props = {
  data: ExtendBlocksType
}

const Carriers: FC<Props> = ({ data }) => {
  const { carriers } = data as CarriersType
  return (
    <Container data-block={BlocksKeys.CARRIERS}>
      <List>
        {carriers.map(carrierId => (
          <CarrierItem carrierId={carrierId} key={carrierId} />
        ))}
      </List>
    </Container>
  )
}

export default memo(Carriers)

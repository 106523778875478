import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
`

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-evenly;
  max-width: 1014px;
  margin: 0 50px;
  width: 100%;
  align-items: center;

  ${p => p.theme.mediaQueries.mobile} {
    margin: 0 20px;
  }
`
